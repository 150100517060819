/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { GlobalStyle } from 'styles/global-styles';

import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { HomePage } from './pages/HomePage/Loadable';
import { LogInPage } from './pages/LogInPage/Loadable';
import { SignUpPage } from './pages/SignUpPage/Loadable';
import { ResetPasswordPage } from './pages/ResetPasswordPage/Loadable';
import { NotFoundPage } from './components/NotFoundPage/Loadable';
import { DocumentPage } from './pages/DocumentPage/Loadable';
import { AboutUsPage } from './pages/AboutUsPage/Loadable';
import { FeaturesPage } from './pages/FeaturesPage/Loadable';
// import { ContactUsPage } from './pages/ContactUsPage/Loadable';

const GlobeVisualization: React.FC = () => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const glyphCount = 1000;

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      75,
      container.clientWidth / container.clientHeight,
      0.1,
      1000,
    );
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });

    renderer.setSize(container.clientWidth, container.clientHeight);
    container.appendChild(renderer.domElement);

    // Create globe mesh
    const geometry = new THREE.SphereGeometry(5, 32, 32);
    const material = new THREE.MeshBasicMaterial({
      color: 0x05d9e8,
      wireframe: true,
      transparent: true,
      opacity: 0.3,
    });
    const globe = new THREE.Mesh(geometry, material);

    // Create glyph particle system
    const glyphGeometry = new THREE.BufferGeometry();
    const glyphPositions = new Float32Array(glyphCount * 3);
    const glyphColors = new Float32Array(glyphCount * 3);
    const glyphSizes = new Float32Array(glyphCount);

    for (let i = 0; i < glyphCount; i++) {
      const theta = Math.random() * Math.PI * 2;
      const phi = Math.acos(Math.random() * 2 - 1);
      const radius = 5.2 + Math.random() * 0.5;

      glyphPositions[i * 3] = radius * Math.sin(phi) * Math.cos(theta);
      glyphPositions[i * 3 + 1] = radius * Math.sin(phi) * Math.sin(theta);
      glyphPositions[i * 3 + 2] = radius * Math.cos(phi);

      glyphColors[i * 3] = 0.0;
      glyphColors[i * 3 + 1] = 0.8 + Math.random() * 0.2;
      glyphColors[i * 3 + 2] = 0.0;

      glyphSizes[i] = 0.1;
    }

    glyphGeometry.setAttribute(
      'position',
      new THREE.BufferAttribute(glyphPositions, 3),
    );
    glyphGeometry.setAttribute(
      'color',
      new THREE.BufferAttribute(glyphColors, 3),
    );
    glyphGeometry.setAttribute(
      'size',
      new THREE.BufferAttribute(glyphSizes, 1),
    );

    const glyphTexture = new THREE.TextureLoader().load(
      'https://threejs.org/examples/textures/sprites/disc.png',
    );
    const glyphMaterial = new THREE.PointsMaterial({
      size: 0.08,
      map: glyphTexture,
      vertexColors: true,
      transparent: true,
      opacity: 0.7,
      blending: THREE.AdditiveBlending,
    });

    const glyphs = new THREE.Points(glyphGeometry, glyphMaterial);
    scene.add(glyphs);
    scene.add(globe);
    camera.position.z = 10;

    // Update glyph visibility based on current step
    const updateGlyphsForStep = (step: number) => {
      const visibleGlyphs = Math.floor((step / 6) * glyphCount);
      const sizes = glyphGeometry.attributes.size.array;

      for (let i = 0; i < glyphCount; i++) {
        sizes[i] = i < visibleGlyphs ? 0.1 : 0;
      }
      glyphGeometry.attributes.size.needsUpdate = true;
    };

    const animate = () => {
      requestAnimationFrame(animate);
      globe.rotation.x += 0.001;
      globe.rotation.y += 0.002;

      glyphs.rotation.y += 0.001;
      const positions = glyphGeometry.attributes.position.array;
      for (let i = 0; i < glyphCount; i++) {
        positions[i * 3 + 1] += Math.sin(Date.now() * 0.001 + i) * 0.001;
      }
      glyphGeometry.attributes.position.needsUpdate = true;

      renderer.render(scene, camera);
    };
    animate();

    // Handle window resize
    const handleResize = () => {
      const width = container.clientWidth;
      const height = container.clientHeight;
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
      renderer.setSize(width, height);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      container.removeChild(renderer.domElement);
      renderer.dispose();
    };
  }, []);

  return (
    <div
      ref={containerRef}
      style={{ width: '100%', height: '100%', margin: 'auto' }}
    />
  );
};

export function App() {
  const { i18n } = useTranslation();
  return (
    <BrowserRouter>
      <Helmet>
        <title>IdeaFoundry: Breakthrough Science Explorer</title>
        <meta
          name="description"
          content="IdeaFoundry: Breakthrough Science Explorer"
        />
      </Helmet>
      {/* <Helmet
        titleTemplate="%s - React Boilerplate"
        defaultTitle="React Boilerplate"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="A React Boilerplate application" />
      </Helmet> */}
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LogInPage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
        <Route path="/docs" element={<DocumentPage />} />
        <Route path="/about" element={<AboutUsPage />} />
        <Route path="/features" element={<FeaturesPage />} />
        {/* <Route path="/contact" element={<ContactUsPage />} /> */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <div id="globe-container" aria-label="3D Globe Visualization">
        <GlobeVisualization />
      </div>
      <Footer />
      <GlobalStyle />
    </BrowserRouter>
  );
}
