import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
:root {
            --primary-color: #ff2a6d;
            --secondary-color: #05d9e8;
            --accent-color: #01012b;
            --background-color: #070b24;
            --text-color: #ffffff; 
            --border-color: #7700ff;
            --font-family-primary: 'Share Tech Mono', monospace;
            --font-family-secondary: 'Orbitron', sans-serif;
            --max-width: 1200px;
            --transition-speed: 0.3s;
            --neon-glow: 0 0 5px rgba(255, 42, 109, 0.7),
                         0 0 10px rgba(255, 42, 109, 0.5),
                         0 0 15px rgba(255, 42, 109, 0.3);
        }

        *, *::before, *::after {
            box-sizing: border-box;
        }

        body {
            font-family: var(--font-family-primary);
            margin: 0;
            padding: 0;
            text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);  
            line-height: 1.8;
            background-color: var(--background-color);
            color: var(--text-color);
            padding-top: 60px; /* Height of fixed header */
        }

        .loading-overlay {
            display: flex;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(7, 11, 36, 0.9);
            justify-content: center;
            align-items: center;
            z-index: 9999;
        }

        .loading-spinner-container {
            text-align: center;
            color: var(--text-color);
        }

        .loading-spinner {
            width: 50px;
            height: 50px;
            border: 4px solid var(--secondary-color);
            border-top: 4px solid var(--primary-color);
            border-radius: 50%;
            animation: spin 1s linear infinite;
            margin: 0 auto 15px;
        }

        .loading-text {
            font-family: var(--font-family-secondary);
            color: var(--secondary-color);
            margin-top: 15px;
            font-size: 1.1rem;
            text-shadow: 0 0 5px var(--secondary-color);
        }

        .mobile-menu-button {
            display: none; /* Hide by default on desktop */
            background: none;
            border: none;
            color: var(--text-color);
            font-size: 1.5rem;
            cursor: pointer;
            padding: 8px;
        }

        .close-menu-button {
            display: none; /* Hidden by default */
            position: absolute;
            top: 20px;
            right: 20px;
            background: none;
            border: none;
            color: var(--text-color);
            font-size: 1.5rem;
            cursor: pointer;
            padding: 8px;
            z-index: 1001;
        }

        .main-header {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            background: rgba(7, 11, 36, 0.95);
            z-index: 1000;
            padding: 1rem;
            box-shadow: 0 2px 10px rgba(5, 217, 232, 0.2);
            border-bottom: 2px solid var(--secondary-color);
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-direction: row;
        }

        .main-header nav {
            display: flex;
            gap: 2rem;
            align-items: center;
        }

        .main-header a {
            color: var(--text-color);
            text-decoration: none;
            font-family: var(--font-family-secondary);
            transition: color 0.3s ease;
            font-size: 0.9rem;
            letter-spacing: 1px;
        }

        .main-header a:hover {
            color: var(--secondary-color);
        }

        .main-header .logo {
            font-size: 1.5rem;
            font-weight: bold;
            color: var(--secondary-color);
            font-family: var(--font-family-secondary);
            text-transform: uppercase;
        }

        #contact-page {
            padding: 60px 20px;
            min-height: 100vh;
            // background-color: var(--background-color);
            width: 100%;
            position: relative;
        }

        .contact-grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 40px;
            max-width: var(--max-width);
            margin: 40px auto;
            padding: 20px;
        }

        .contact-form {
            background: rgba(7, 11, 36, 0.95);
            padding: 30px;
            border-radius: 8px;
            border: 2px solid var(--secondary-color);
            box-shadow: var(--neon-glow);
            margin-bottom: 20px;
        }

        .social-icons a {
            margin-right: 20px;
        }

        .contact-info {
            padding: 30px;
        }

        .contact-form form {
            background: transparent; /* Remove the background from the form */
            border: none; /* Remove the border from the form */
            box-shadow: none; /* Remove the box-shadow from the form */
            padding: 0; /* Remove padding from the form */
        }

        .contact-form input,
        .contact-form textarea {
            width: 100%;
            padding: 12px;
            margin-bottom: 15px;
            background: rgba(1, 1, 43, 0.5);
            border: 1px solid var(--secondary-color);
            color: var(--text-color);
            border-radius: 4px;
            font-family: var(--font-family-primary);
        }

        .contact-form .form-group {
            margin-bottom: 20px;
            width: 100%;
        }

        .contact-form .cta-button {
            width: 100%;
            padding: 15px;
            font-size: 1rem;
            margin-top: 10px;
        }

        .contact-form #contact-form {
            display: block;
            align-items: unset;
            padding: 0;
            margin: 0;
        }

        .about-hero {
            padding: 120px 40px;
            text-align: center;
            background: linear-gradient(135deg, var(--background-color) 0%, var(--accent-color) 100%);
            border-bottom: 3px solid var(--primary-color);
            box-shadow: var(--neon-glow);
        }
        
        .about-hero h1 {
            font-size: 4rem;
            margin-bottom: 2rem;
            color: var(--secondary-color);
            text-transform: uppercase;
            font-family: var(--font-family-secondary);
        }
        
        .about-section {
            padding: 80px 20px;
            max-width: var(--max-width);
            margin: 0 auto;
        }
        
        .about-grid {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            gap: 40px;
            margin: 60px 0;
        }
        
        .about-card {
            background: rgba(7, 11, 36, 0.95);
            border: 2px solid var(--secondary-color);
            padding: 30px;
            border-radius: 8px;
            transition: all 0.3s ease;
            box-shadow: 0 0 15px rgba(5, 217, 232, 0.2);
        }
        
        .about-card:hover {
            transform: translateY(-5px);
            box-shadow: 0 5px 25px rgba(5, 217, 232, 0.3);
            border-color: var(--primary-color);
        }
        
        .about-card h3 {
            color: var(--secondary-color);
            font-family: var(--font-family-secondary);
            margin-bottom: 1rem;
            font-size: 1.5rem;
        }
        
        .team-section {
            margin-top: 80px;
        }
        
        .team-grid {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            gap: 30px;
            margin-top: 40px;
        }
        
        .team-member {
            text-align: center;
            padding: 20px;
            background: rgba(7, 11, 36, 0.95);
            border: 2px solid var(--secondary-color);
            border-radius: 8px;
            transition: all 0.3s ease;
        }
        
        .team-member img {
            width: 150px;
            height: 150px;
            border-radius: 50%;
            margin-bottom: 15px;
            border: 3px solid var(--primary-color);
        }
        
        .timeline {
            position: relative;
            max-width: 800px;
            margin: 60px auto;
            padding: 40px 0;
        }
        
        .timeline::after {
            content: '';
            position: absolute;
            width: 2px;
            background: var(--secondary-color);
            top: 0;
            bottom: 0;
            left: 50%;
            box-shadow: 0 0 10px rgba(5, 217, 232, 0.5);
        }
        
        .timeline-item {
            padding: 20px 40px;
            position: relative;
            background: rgba(7, 11, 36, 0.95);
            border: 2px solid var(--secondary-color);
            width: calc(50% - 40px);
            margin: 40px 0;
            border-radius: 8px;
        }
        
        .timeline-item:nth-child(odd) {
            left: 0;
        }
        
        .timeline-item:nth-child(even) {
            left: calc(50% + 40px);
        }

        .feature-card {
            background: rgba(7, 11, 36, 0.95);
            border: 2px solid var(--secondary-color);
            padding: 40px;
            border-radius: 8px;
            margin-bottom: 30px;
            transition: all 0.3s ease;
            display: grid;
            grid-template-columns: 1fr 2fr;
            gap: 30px;
            align-items: center;
        }
        
        .feature-card:hover {
            transform: translateY(-5px);
            box-shadow: 0 5px 25px rgba(5, 217, 232, 0.3);
            border-color: var(--primary-color);
        }
        
        .feature-icon {
            font-size: 3rem;
            color: var(--secondary-color);
            text-align: center;
        }
        
        .feature-content h3 {
            color: var(--secondary-color);
            font-family: var(--font-family-secondary);
            margin-bottom: 1rem;
            font-size: 1.5rem;
        }
        
        .features-grid {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            gap: 30px;
            margin: 60px 0;
        }
        
        .feature-highlight {
            text-align: center;
            padding: 30px;
            background: rgba(7, 11, 36, 0.95);
            border: 2px solid var(--secondary-color);
            border-radius: 8px;
            transition: all 0.3s ease;
        }
        
        .feature-highlight:hover {
            transform: translateY(-5px);
            border-color: var(--primary-color);
        }
        
        .feature-stats {
            font-size: 2.5rem;
            color: var(--primary-color);
            font-family: var(--font-family-secondary);
            margin: 1rem 0;
        }

        .footer-bottom {
            text-align: center;
            margin-top: 2rem;
            padding-top: 2rem;
            border-top: 1px solid rgba(5, 217, 232, 0.2);
            color: var(--text-color);
            font-size: 0.9rem;
        }

        .main-footer {
            background: rgba(7, 11, 36, 0.95);
            border-top: 2px solid var(--secondary-color);
            padding: 3rem 2rem;
            margin-top: 4rem;
            box-shadow: 0 -2px 10px rgba(5, 217, 232, 0.2);
        }

        .footer-content {
            max-width: var(--max-width);
            margin: 0 auto;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
            gap: 2rem;
        }

        .footer-section {
            padding: 1rem;
        }

        .footer-section h3 {
            color: var(--secondary-color);
            font-family: var(--font-family-secondary);
            margin-bottom: 1rem;
            font-size: 1.2rem;
        }

        .footer-section ul {
            list-style: none;
            padding: 0;
        }

        .footer-section li {
            margin-bottom: 0.5rem;
        }

        .footer-section a {
            color: var(--text-color);
            text-decoration: none;
            transition: color 0.3s ease;
        }

        .footer-section a:hover {
            color: var(--secondary-color);
        }

        body::after {
            content: "";
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            background: repeating-linear-gradient(
                0deg,
                transparent 0%,
                rgba(5, 217, 232, 0.03) 50%,
                transparent 100%
            );
            pointer-events: none;
            animation: scan 10s linear infinite;
        }

        @keyframes scan {
            from { transform: translateY(0); }
            to { transform: translateY(100vh); }
        }

        header {
            background: linear-gradient(135deg, #070b24 0%, #1a1b4b 50%, #070b24 100%);
            border-bottom: 3px solid var(--primary-color);
            box-shadow: 
                var(--neon-glow),
                inset 0 0 50px rgba(5, 217, 232, 0.2);
            color: white;
            padding: 80px 20px; /* Reduced default padding */
            min-height: 10vh; /* Ensure minimum height */
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        header h1 {
            font-family: var(--font-family-secondary);
            font-size: clamp(3rem, 10vw, 8rem); /* Responsive font size */
            margin: 0 0 30px 0; /* Adjusted margins */
            text-transform: uppercase;
            font-weight: 900;
            letter-spacing: clamp(2px, 1vw, 6px); /* Responsive letter spacing */
            background: linear-gradient(
                45deg,
                #ff2a6d 0%,
                #ffffff 25%,
                #05d9e8 50%,
                #ffffff 75%,
                #ff2a6d 100%
            );
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            animation: shine 4s linear infinite;
            text-shadow: 
                0 0 15px rgba(255, 42, 109, 0.8),
                0 0 25px rgba(255, 42, 109, 0.5),
                0 0 35px rgba(5, 217, 232, 0.3);
            position: relative;
            transform-style: preserve-3d;
            perspective: 1000px;
        }

        @keyframes shine {
            0% {
                background-position: 0% 50%;
                transform: translateZ(0px);
            }
            25% {
                transform: translateZ(10px);
            }
            50% {
                background-position: 100% 50%;
                transform: translateZ(0px);
            }
            75% {
                transform: translateZ(10px);
            }
            100% {
                background-position: 0% 50%;
                transform: translateZ(0px);
            }
        }

        .cta-button {
            display: inline-block;
            font-family: var(--font-family-secondary);
            font-size: clamp(1.2rem, 3vw, 2rem); /* Responsive font size */
            padding: clamp(15px, 3vw, 20px) clamp(25px, 5vw, 40px); /* Responsive padding */
            margin-top: 20px;
            background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
            color: var(--text-color);
            text-decoration: none;
            border-radius: 50px;
            border: 2px solid var(--secondary-color);
            box-shadow: 
                0 0 20px rgba(255, 42, 109, 0.5),
                0 0 40px rgba(5, 217, 232, 0.3),
                inset 0 0 15px rgba(255, 255, 255, 0.3);
            transition: all 0.3s ease;
            text-transform: uppercase;
            letter-spacing: 2px;
            text-shadow: 
                2px 2px 4px rgba(0, 0, 0, 0.5),
                0 0 10px rgba(255, 255, 255, 0.5);
            position: relative;
            z-index: 2;
        }

        .cta-button:hover {
            transform: translateY(-5px) scale(1.05);
            box-shadow: 
                0 0 30px rgba(255, 42, 109, 0.7),
                0 0 50px rgba(5, 217, 232, 0.5),
                inset 0 0 20px rgba(255, 255, 255, 0.5);
            text-shadow: 
                2px 2px 4px rgba(0, 0, 0, 0.7),
                0 0 15px rgba(255, 255, 255, 0.7);
        }

        .cta-button:active {
            transform: translateY(2px) scale(0.98);
        }

        /* Remaining styles unchanged */
        .container {
            max-width: var(--max-width);
            margin: 0 auto;
            padding: 40px 20px;
        }

        .step-indicator {
            display: flex;
            justify-content: space-between;
            margin-bottom: 40px;
        }

        .step {
            flex: 1;
            text-align: center;
            position: relative;
        }

        .step:not(:last-child)::after {
            display: none;
        }

        .step-number {
            background-color: var(--accent-color);
            border: 2px solid var(--primary-color);
            box-shadow: var(--neon-glow);
            color: var(--text-color);
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;
            font-size: 1.2rem;
            transition: background-color var(--transition-speed), color var(--transition-speed);
            text-shadow: 0 0 5px var(--primary-color),
                         0 0 10px rgba(255, 42, 109, 0.5);
        }

        .step.active .step-number,
        .step.completed .step-number {
            background-color: var(--primary-color);
            box-shadow: 0 0 10px var(--primary-color);
            color: var(--text-color);
        }

        section {
            display: none;
            animation: fadeIn var(--transition-speed) ease-in-out;
        }

        section.active {
            display: block;
        }

        @keyframes fadeIn {
            from { opacity: 0; transform: translateY(20px); }
            to { opacity: 1; transform: translateY(0); }
        }

        .moonshot-input-container {
            max-width: 800px;
            margin: 0 auto 40px auto;
            display: flex;
            align-items: center;
            padding: 20px;
            background: rgba(7, 11, 36, 0.95);
            border: 2px solid var(--secondary-color);
            box-shadow: var(--neon-glow);
        }

        .moonshot-input-container input {
            flex: 1;
            padding: 20px;
            font-size: 1.2rem;
            background: rgba(7, 11, 36, 0.95);
            color: #ffffff;
            border: 2px solid var(--secondary-color);
            border-radius: 8px;
            margin-right: 15px;
            transition: border-color var(--transition-speed);
            text-shadow: none;
        }

        .moonshot-input-container input:focus {
            border-color: var(--primary-color);
            outline: none;
        }

        .moonshot-input-container button {
            padding: 20px 40px;
            font-size: 1.2rem;
            background-color: var(--primary-color);
            color: var(--text-color);
            border: 1px solid var(--secondary-color);
            border-radius: 8px;
            cursor: pointer;
            transition: background-color var(--transition-speed), transform var(--transition-speed);
            font-weight: 600;
            text-shadow: 0 0 5px var(--secondary-color);
            box-shadow: 0 0 10px rgba(5, 217, 232, 0.3);
        }

        .moonshot-input-container button:hover,
        .moonshot-input-container button:focus {
            background-color: #ff8f00;
            transform: translateY(-3px);
            outline: none;
        }

        .content-section h2 {
            font-family: var(--font-family-secondary);
            font-size: 2rem;
            color: var(--secondary-color);
            margin-top: 0;
            margin-bottom: 20px;
            text-align: center;
            text-shadow: 0 0 10px var(--secondary-color),
                         0 0 20px rgba(5, 217, 232, 0.5);
        }

        .content-section p {
            font-size: 1rem;
            color: #ffffff;  
            line-height: 1.8;
        }

        .powered-by {
            text-align: center;
            font-family: var(--font-family-secondary);
            color: var(--secondary-color);
            margin-top: 10px;
            font-size: 0.9rem;
            letter-spacing: 1px;
            opacity: 0.8;
            text-shadow: 0 0 5px var(--secondary-color);
        }

        .back-button {
            background-color: var(--border-color);
            color: var(--text-color);
            padding: 10px 20px;
            border: none;
            border-radius: 25px;
            cursor: pointer;
            font-weight: 600;
            transition: background-color var(--transition-speed), transform var(--transition-speed);
            margin-bottom: 20px;
            display: inline-block;
        }

        .back-button:hover,
        .back-button:focus {
            background-color: var(--primary-color);
            color: var(--text-color);
            transform: translateY(-3px);
            outline: none;
        }

        .generate-mvp-button {
            background-color: var(--primary-color);
            color: var(--text-color);
            padding: 15px 30px;
            border: 1px solid var(--secondary-color);
            border-radius: 30px;
            cursor: pointer;
            font-weight: 600;
            transition: all 0.3s ease;
            margin: 30px auto;
            display: block;
            font-size: 1.1rem;
            text-shadow: 0 0 5px var(--secondary-color);
            box-shadow: 0 0 10px rgba(5, 217, 232, 0.3);
        }

        .generate-mvp-button:hover,
        .generate-mvp-button:focus {
            background-color: #ff8f00;
            transform: translateY(-3px);
            outline: none;
        }

        #globe-container {
            position: fixed;
            width: 100%;
            // max-width: 400px;
            // height: 400px;
            height: 100%;
            margin: 40px auto;
            top: 180px;
            left: 0;
            z-index: -1;
            opacity: 70%;
        }

        #globe-container canvas {
            transition: opacity 0.3s ease;
        }

        @media print {
            .cta-button, .toggle-button, .select-mvp-button {
                display: none !important;
            }

            h1 {
                font-size: 24px !important;
            }
            h2 {
                font-size: 20px !important;
            }
            h3, h4, h5 {
                font-size: 16px !important;
            }
            p, li {
                font-size: 12px !important;
            }

            img, canvas, svg {
                max-width: 100% !important;
                height: auto !important;
            }
        }

        .fundamental-item {
            padding: 25px;
            margin: 20px 0;
            background: rgba(7, 11, 36, 0.95);  
            border: 2px solid var(--secondary-color);
            box-shadow: 0 0 15px rgba(5, 217, 232, 0.3);
            transition: all 0.3s ease;
        }

        .fundamental-item:hover {
            border-color: var(--primary-color);
            box-shadow: 0 0 20px rgba(255, 42, 109, 0.3);
        }

        .select-fundamental-button {
            background-color: var(--primary-color);
            color: var(--text-color);
            padding: 10px 20px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            margin-top: 10px;
            text-shadow: 0 0 5px var(--secondary-color);
        }

        .select-fundamental-button:hover {
            background-color: #ff8f00;
        }

        .mvp-concept {
            border: 2px solid var(--secondary-color);
            background: rgba(7, 11, 36, 0.95);
            padding: 25px;
            margin: 30px 0;
            border-radius: 8px;
            box-shadow: 0 0 15px rgba(5, 217, 232, 0.3);
            transition: all 0.3s ease;
        }

        .mvp-concept:hover {
            border-color: var(--primary-color);
            box-shadow: 0 0 20px rgba(255, 42, 109, 0.3);
        }

        .mvp-concept.selected {
            border-color: var(--primary-color);
            box-shadow: 0 0 25px rgba(255, 42, 109, 0.5);
        }

        .mvp-concept h3 {
            color: var(--secondary-color);
            font-family: var(--font-family-secondary);
            margin-top: 0;
            padding-bottom: 15px;
            border-bottom: 1px solid var(--secondary-color);
        }

        .mvp-concept .immediate-action-step,
        .mvp-concept .budget-category,
        .mvp-concept .required-resource,
        .mvp-concept .potential-partner {
            border: 1px solid var(--border-color);
            background: rgba(1, 1, 43, 0.5);
            padding: 15px;
            margin: 10px 0;
            border-radius: 4px;
        }

        .mvp-concept h4 {
            color: var(--primary-color);
            margin-top: 20px;
            font-family: var(--font-family-secondary);
        }

        .mvp-concept h5 {
            color: var(--secondary-color);
            margin-top: 10px;
            margin-bottom: 5px;
        }

        .loading-state {
            text-align: center;
            padding: 20px;
            background: rgba(7, 11, 36, 0.95);
            border: 2px solid var(--secondary-color);
            margin: 20px 0;
        }

        .loading-spinner {
            border: 4px solid var(--secondary-color);
            border-top: 4px solid var(--primary-color);
            border-radius: 50%;
            width: 40px;
            height: 40px;
            animation: spin 1s linear infinite;
            margin: 0 auto 15px auto;
        }

        .error-state {
            text-align: center;
            padding: 20px;
            background: rgba(255, 0, 0, 0.1);
            border: 2px solid var(--primary-color);
            margin: 20px 0;
        }

        .error-state button {
            background-color: var(--primary-color);
            color: var(--text-color);
            padding: 10px 20px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            margin-top: 10px;
        }

        @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
        }

        .generate-elevator-pitch-button {
            display: block;
            width: 100%;
            padding: 15px 30px;
            margin-top: 20px;
            background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
            color: var(--text-color);
            border: none;
            border-radius: 8px;
            cursor: pointer;
            font-family: var(--font-family-secondary);
            font-size: 1.1rem;
            text-transform: uppercase;
            letter-spacing: 1px;
            transition: all 0.3s ease;
            box-shadow: 0 0 15px rgba(5, 217, 232, 0.3);
        }

        .generate-elevator-pitch-button:hover {
            transform: translateY(-3px);
            box-shadow: 0 0 20px rgba(255, 42, 109, 0.3);
            background: linear-gradient(135deg, var(--secondary-color), var(--primary-color));
        }

        .login-section {
            display: none;
            min-height: 70vh;
            padding: 40px 20px;
            animation: fadeIn 0.3s ease-in-out;
        }

        .login-section.active {
            display: block;
        }

        .login-container {
            max-width: 400px;
            margin: 40px auto;
            padding: 30px;
            background: rgba(7, 11, 36, 0.95);
            border: 2px solid var(--secondary-color);
            border-radius: 8px;
            box-shadow: var(--neon-glow);
        }

        .login-container h2 {
            color: var(--secondary-color);
            text-align: center;
            margin-bottom: 30px;
            font-family: var(--font-family-secondary);
        }

        .login-form {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }

        .signup-section {
            display: none;
            min-height: 70vh;
            padding: 40px 20px;
            animation: fadeIn 0.3s ease-in-out;
        }

        .signup-section.active {
            display: block;
        }

        .signup-container {
            max-width: 400px;
            margin: 40px auto;
            padding: 30px;
            background: rgba(7, 11, 36, 0.95);
            border: 2px solid var(--secondary-color);
            border-radius: 8px;
            box-shadow: var(--neon-glow);
        }

        .signup-container h2 {
            color: var(--secondary-color);
            text-align: center;
            margin-bottom: 30px;
            font-family: var(--font-family-secondary);
        }

        .signup-form {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }

        .auth-button {
            width: 100%;
            padding: 12px;
            background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
            color: var(--text-color);
            border: none;
            border-radius: 4px;
            font-family: var(--font-family-secondary);
            cursor: pointer;
            transition: transform 0.3s ease, box-shadow 0.3s ease;
            text-transform: uppercase;
            letter-spacing: 1px;
            margin-top: 20px;
        }

        .auth-button:hover {
            transform: translateY(-2px);
            box-shadow: 0 0 15px rgba(5, 217, 232, 0.5);
        }

        .reset-password-section {
            display: none;
            min-height: 70vh;
            padding: 40px 20px;
            animation: fadeIn 0.3s ease-in-out;
        }

        .reset-password-section.active {
            display: block;
        }

        .reset-password-container {
            max-width: 400px;
            margin: 40px auto;
            padding: 30px;
            background: rgba(7, 11, 36, 0.95);
            border: 2px solid var(--secondary-color);
            border-radius: 8px;
            box-shadow: var(--neon-glow);
        }

        .reset-password-container h2 {
            color: var(--secondary-color);
            text-align: center;
            margin-bottom: 30px;
            font-family: var(--font-family-secondary);
            font-size: 1.8rem;
        }

        .form-error {
            color: var(--primary-color);
            font-size: 0.9rem;
            margin-top: 10px;
            text-align: center;
        }

        .form-success {
            color: #4CAF50;
            font-size: 0.9rem;
            margin-top: 10px;
            text-align: center;
        }

        .form-group {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }

        .form-group label {
            color: var(--text-color);
            font-size: 0.9rem;
        }

        .form-group input {
            padding: 12px;
            background: rgba(1, 1, 43, 0.5);
            border: 1px solid var(--secondary-color);
            border-radius: 4px;
            color: var(--text-color);
            font-family: var(--font-family-primary);
            transition: border-color 0.3s ease;
        }

        .form-group input:focus {
            outline: none;
            border-color: var(--primary-color);
            box-shadow: 0 0 5px rgba(5, 217, 232, 0.3);
        }

        .login-button {
            padding: 12px;
            background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
            color: var(--text-color);
            border: none;
            border-radius: 4px;
            font-family: var(--font-family-secondary);
            cursor: pointer;
            transition: transform 0.3s ease, box-shadow 0.3s ease;
        }

        .login-button:hover {
            transform: translateY(-2px);
            box-shadow: 0 0 15px rgba(5, 217, 232, 0.5);
        }

        .signup-button {
            padding: 12px;
            background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
            color: var(--text-color);
            border: none;
            border-radius: 4px;
            font-family: var(--font-family-secondary);
            cursor: pointer;
            transition: transform 0.3s ease, box-shadow 0.3s ease;
        }

        .signup-button:hover {
            transform: translateY(-2px);
            box-shadow: 0 0 15px rgba(5, 217, 232, 0.5);
        }

        .forgot-password {
            text-align: center;
            margin-top: 15px;
        }

        .forgot-password a,
        .back-to-login a  {
            color: var(--secondary-color);
            text-decoration: none;
            font-size: 0.9rem;
            transition: color 0.3s ease;
        }

        .forgot-password a:hover,
        .back-to-login a:hover  {
            color: var(--primary-color);
        }

        .signup-link {
            text-align: center;
            margin-top: 15px;
        }

        .signup-link a {
            color: var(--secondary-color);
            text-decoration: none;
            font-size: 0.9rem;
            transition: color 0.3s ease;
        }

        .signup-link a:hover {
            color: var(--primary-color);
        }

        .container {
            width: 100%;
            // max-width: 1024px; /* Set a max width if needed */
            // margin: 0 auto; /* Center the container */
            // padding: 0 auto; /* Add some padding */
        }

        .pdf-viewer {
            margin-top: 60px;
            width: 100%; /* Ensure the PDF viewer takes full width */
            overflow: auto; /* Allow scrolling if content overflows */
        }

        .pdf-viewer .react-pdf__Page {
            // margin: 0 auto; /* Center the page */
        }
        
        .page-break {
            margin-bottom: 20px; /* Space between pages */
            border: 1px solid #ccc; /* Optional border */
            padding: 10px; /* Optional padding */
            display: flex;
            justify-content: center; /* Center the page */
            width: 100%; /* Ensure the page break takes full width */
        }

        .navigation-container {
            display: flex;
            align-items: center; /* Center items vertically */
            justify-content: center; 
            margin-top: 20px; /* Space above the navigation */
            flex-wrap: wrap; /* Allow wrapping on smaller screens */
        }

        .navigation-buttons {
            display: flex; /* Align buttons in a row */
            align-items: center; /* Center buttons vertically */
        }

        .common-button {
            padding: 10px; /* Padding for buttons */
            background: linear-gradient(135deg, var(--primary-color), var(--secondary-color)); /* Gradient background */
            color: var(--text-color); /* Text color */
            border: none; /* Remove border */
            border-radius: 4px; /* Rounded corners */
            font-family: var(--font-family-secondary); /* Font family */
            cursor: pointer; /* Pointer cursor on hover */
            transition: transform 0.3s ease, box-shadow 0.3s ease; /* Transition effects */
            text-transform: uppercase; /* Uppercase text */
            letter-spacing: 1px; /* Letter spacing */
            margin: 5px; /* Add margin for spacing */
        }

        .common-button:hover {
            transform: translateY(-2px); /* Lift effect on hover */
            box-shadow: 0 0 15px rgba(5, 217, 232, 0.5); /* Shadow effect on hover */
        }

        .common-button:disabled {
            background: #ccc; /* Gray background for disabled buttons */
            cursor: not-allowed; /* Not-allowed cursor for disabled buttons */
        }

        .page-number {
            margin: 0 10px; /* Space around the page number */
            font-size: 16px; /* Font size for the page number */
            font-weight: bold; /* Bold text for emphasis */
        }
        
        .logo-image {
            height: 40px; /* Adjust height as needed */
            width: auto; /* Maintain aspect ratio */
        }

        @media screen and (max-width: 768px) {
            .contact-grid {
                grid-template-columns: 1fr;
                gap: 20px;
            }

            .contact-form,
            .contact-info {
                margin-bottom: 20px;
            }

            .contact-form {
                padding: 20px;
            }
            
            .contact-form input,
            .contact-form textarea {
                padding: 10px;
            }

            .feature-card {
                grid-template-columns: 1fr;
                text-align: center;
                padding: 20px;
            }
            
            .feature-icon {
                margin-bottom: 20px;
            }
            
            .features-grid {
                grid-template-columns: 1fr;
                gap: 20px;
                margin: 30px 0;
            }

            .about-hero {
                padding: 100px 20px 60px 20px;
            }

            .about-hero h1 {
                font-size: 2.5rem;
            }

            .timeline::after {
                left: 31px;
            }

            .timeline-item {
                width: 100%;
                padding-left: 70px;
                padding-right: 25px;
            }

            .timeline-item:nth-child(odd),
            .timeline-item:nth-child(even) {
                left: 0;
            }

            .about-grid {
                grid-template-columns: 1fr;
                gap: 20px;
                margin: 30px 0;
            }

            .team-grid {
                grid-template-columns: 1fr;
                gap: 20px;
            }
               
            .about-section {
                padding: 40px 15px;
            }

            .team-section {
                margin-top: 40px;
            }

            .about-card h3 {
                font-size: 1.3rem;
            }

            .moonshot-input-container {
                flex-direction: column;
                align-items: stretch;
            }

            .moonshot-input-container input {
                width: 100%;
                margin-right: 0px;
            }

            .moonshot-input-container button {
                margin-top: 20px;
                width: 100%;
            }

            .mobile-menu-button {
                display: block; /* Show only on mobile */
            }

            .close-menu-button {
                display: block; /* Show only on mobile */
            }

            .main-header {
                justify-content: space-between;
            }

            .main-header nav {
                position: fixed;
                display: none;
                top: 0;
                right: 0;
                height: 100%;
                width: 250px;
                background: var(--background-color);
                flex-direction: column;
                padding: 80px 20px 20px;
                transition: right 0.3s ease;
                border-left: 2px solid var(--secondary-color);
                box-shadow: -5px 0 15px rgba(0, 0, 0, 0.3);
            }

            .main-header nav.active {
                display: flex;
                right: 0;
            }

            .main-header nav a {
                font-size: 1.1rem;
                padding: 15px 0;
                width: 100%;
                text-align: center;
                border-bottom: 1px solid rgba(5, 217, 232, 0.2);
            }

            .main-header nav a:last-child {
                border-bottom: none;
            }

            .main-header nav .cta-button {
                margin-top: 20px;
                width: 100%;
                text-align: center;
            }

            header {
                padding: 40px 15px;
            }
            
            header h1 {
                margin-bottom: 20px;
                text-align: center;
            }

            .powered-by {
                font-size: 0.8rem;
                padding: 0 15px;
                text-align: center;
            }
        }

        /* Extra small devices */
        @media screen and (max-width: 480px) {
            header {
                padding: 30px 10px;
            }
            
            header h1 {
                font-size: clamp(1rem, 10vw, 3rem);
            }

            .cta-button {
                padding: 12px 20px;
                font-size: 0.6rem;
            }

            .step-number {
                font-size: 0.7rem;
            }

            .step-title {
                font-size: 0.6rem;
            }

            .login-container {
                margin: 20px auto;
                padding: 20px;
            }

            .signup-container {
                margin: 20px auto;
                padding: 20px;
            }

            .form-group input {
                font-size: 0.7rem;
            }

            .moonshot-input-container input {
                font-size: 0.7rem;
            }

            .moonshot-input-container button {
                font-size: 0.7rem;
            }
        }`;
