/**
 * Asynchronously loads the component for DocumentPage
 */

import { lazyLoad } from 'utils/loadable';

export const DocumentPage = lazyLoad(
  () => import('./index'),
  module => module.DocumentPage,
);
