import * as React from 'react';

export function Footer() {
  return (
    <>
      <footer className="main-footer">
        <div className="footer-content">
          <div className="footer-section">
            <h3>About IdeaFoundry</h3>
            <p>
              IdeaFoundry empowers millions globally by turning concepts into
              reality in minutes and with little to no resources required.
            </p>
            {/* <p>
              Using AI and Blockchain, it addresses the pain points of slow idea
              development and limited resources, enabling rapid creation of real
              projects through entering a single word or phrase as a prompt,
              helping individuals or projects of all sizes and means innovate
              and create value quickly.
            </p> */}
          </div>
          <div className="footer-section">
            <h3>Quick Links</h3>
            <ul>
              <li>
                <a href="/about">About</a>
              </li>
              <li>
                <a href="/features">Features</a>
              </li>
              {/* <li>
                <a href="/contact">Contact</a>
              </li> */}
              <li>
                <a href="/docs">Docs</a>
              </li>
              <li>
                <a href="#privacy">Privacy Policy</a>
              </li>
              <li>
                <a href="#terms">Terms of Service</a>
              </li>
            </ul>
          </div>
          <div className="footer-section">
            <h3>Connect</h3>
            <ul>
              <li>
                <a
                  href="https://x.com/IdeaFoundryAI"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Twitter
                </a>
              </li>
              {/* <li>
                <a
                  href="https://linkedin.com/company/ideafoundry"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LinkedIn
                </a>
              </li> */}
              <li>
                <a
                  href="https://github.com/ideafoundryai"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GitHub
                </a>
              </li>
              {/* <li>
                <a
                  href="https://discord.gg/ideafoundry"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Discord
                </a>
              </li> */}
            </ul>
          </div>
          <div className="footer-section">
            <h3>Contact Us</h3>
            <ul>
              {/* <li>Email: info@ideafoundry.com</li> */}
              <li>Email: info.ideafoundry@gmail.com</li>
              {/* <li>Phone: +1 (555) 123-4567</li>
              <li>Address: Innovation Hub, Tech Valley, CA 94103</li> */}
            </ul>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; 2024 IdeaFoundry. All rights reserved.</p>
        </div>
      </footer>
    </>
  );
}
